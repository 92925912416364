import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD6tPqy42tN6uF3RoLlx87IimxXWVCbFWs",
  authDomain: "sport-league-e35d7.firebaseapp.com",
  projectId: "sport-league-e35d7",
  storageBucket: "sport-league-e35d7.appspot.com",
  messagingSenderId: "515296229458",
  appId: "1:515296229458:web:51917438056a955ce9878b"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

export {
  db
};