import { useState } from 'react';

import './App.css';

import BarLoader from "react-spinners/BarLoader";

import { db } from "./util/firebase";
import { collection, addDoc } from "firebase/firestore";

import Swal from 'sweetalert2'

// import classnames from 'classnames';

// eslint-disable-next-line
import { Link } from "react-scroll";

import {useTranslation} from "react-i18next";

import { IoClose } from 'react-icons/io5';
import { BsInstagram, BsTwitterX } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';

import { Modal, ModalBody } from 'reactstrap';

// import { ScrollMenu } from 'react-horizontal-scrolling-menu';
// import 'react-horizontal-scrolling-menu/dist/styles.css';

function App() {
  const [isLoading, setIsLoading] = useState(false);

  const {t, i18n} = useTranslation('common');

  const [activeLanguage, setActiveLanguage] = useState('en')

  const [languageModalIsOpen, setLanguageModalIsOpen] = useState(false);
  const toggleLanguageModal = () => {
    setLanguageModalIsOpen(!languageModalIsOpen);
  }

  const toggleLanguage = (language) => {
    i18n.changeLanguage(language);
    setActiveLanguage(language);
    toggleLanguageModal();
  }

  // eslint-disable-next-line
  const [earlySignupEmail, setEarlySignupEmail] = useState();
  const [contactFormEmail, setContactFormEmail] = useState();
  const [contactFormMessage, setContactFormMessage] = useState();

  // eslint-disable-next-line
  const earlySignup = async () => {
    if (earlySignupEmail){
      setIsLoading(true);

      await addDoc(collection(db, "mail"), {
        to: [
          'contact@sportleague.net'
        ],
        message: {
          subject: 'You have a new early signup!',
          html: `
            <table border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse:collapse; padding:0; margin:0px;">
            <tr valign="top">
            <td align="center">
            <div style="background-color:#111835;padding:1rem;font-family: 'Poppins', sans-serif;max-width:400px;margin-top:-8px;margin-bottom:-8px">
              <style>
                @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
                @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
              </style>
              <div style="display:flex;justify-content:center;">
                <img
                  alt="logo"
                  src="https://res.cloudinary.com/da3qtg7t5/image/upload/v1702401521/Sport%20League/Logos/01_-_SL_Logo_3_qfyqdr.png"
                  style="width:100px;margin-bottom:2rem;margin-top:1rem;"
                />
              </div>
              <div style="border-bottom:2px solid #FABB00;width:100%;margin-bottom:1rem;"></div>
              <p style="color:#FABB00;text-align:center;font-size:24px;font-weight:bold;font-family:'Oswald',sans-serif;">NEW SIGNUP</p>
              <div style="background-color:#FFFFFF;padding:1rem;border-radius:20px;">
                Hi! You have a new early signup for Sport League from ${earlySignupEmail}.<br/><br/>
                Best regards,<br/>
                Sport League
              </div>
              <div style="border-bottom:2px solid #FABB00;width:100%;margin-top:3rem;margin-bottom:1rem;"></div>
              <table style="width:100%">
                <tr style="width:100%;">
                  <td style="width:100%;text-align:center;">
                    <a href='https://www.instagram.com/sportleague.sv' target='_blank' rel='noreferrer' style="text-decoration:none;">
                      <img
                        alt="socialsIcon"
                        src="https://firebasestorage.googleapis.com/v0/b/sport-league-e35d7.appspot.com/o/assets%2Femails%2FemailInstagramIcon.png?alt=media&token=45fba107-170f-4eb6-9881-384f6797336e"
                        style="width:52px;height:52px;margin-right:1rem;max-height:52px"
                      />
                    </a>
                    <a href='https://www.facebook.com/people/Sport-League/100086265026537' target='_blank' rel='noreferrer' style="text-decoration:none;">
                      <img
                        alt="socialsIcon"
                        src="https://firebasestorage.googleapis.com/v0/b/sport-league-e35d7.appspot.com/o/assets%2Femails%2FemailFacebookIcon.png?alt=media&token=c452a797-681e-4b34-a42c-87a4c982fee8"
                        style="width:52px;height:52px;margin-right:1rem;max-height:52px"
                      />
                    </a>
                    <a href='https://twitter.com/sportleaguesv?t=GCJQbcarcRruthH2qq2WRA' target='_blank' rel='noreferrer' style="text-decoration:none;">
                      <img
                        alt="socialsIcon"
                        src="https://firebasestorage.googleapis.com/v0/b/sport-league-e35d7.appspot.com/o/assets%2Femails%2FemailXIcon.png?alt=media&token=b8ef48f5-5dcf-4f28-9e99-518e4471c955"
                        style="width:52px;height:52px;max-height:52px"
                      />
                    </a>
                  </td>
                </tr>
              </table>
              <div style="border-bottom:2px solid #FABB00;width:100%;margin-bottom:1rem;margin-top:0.5rem;"></div>
              <p style="color:#FFFFFF;text-align:center;margin-bottom:1rem;font-size:14px">
                © 2023 Sport League, all rights reserved
              </p>
            </div>
            </td>
            </tr>
            </table>
            `
        }
      });

      await addDoc(collection(db, "mail"), {
        to: [
          earlySignupEmail
        ],
        message: {
          subject: t('getReadyForSportLeague'),
          html: `
            <table border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse:collapse; padding:0; margin:0px;">
            <tr valign="top">
            <td align="center">
            <div style="background-color:#111835;padding:1rem;font-family: 'Poppins', sans-serif;max-width:400px;margin-top:-8px;margin-bottom:-8px">
              <style>
                @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
                @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
              </style>
              <div style="display:flex;justify-content:center;">
                <img
                  alt="logo"
                  src="https://res.cloudinary.com/da3qtg7t5/image/upload/v1702401521/Sport%20League/Logos/01_-_SL_Logo_3_qfyqdr.png"
                  style="width:100px;margin-bottom:2rem;margin-top:1rem"
                />
              </div>
              <div style="border-bottom:2px solid #FABB00;width:100%;margin-bottom:1rem;"></div>
              <p style="color:#FABB00;text-align:center;font-size:24px;font-weight:bold;font-family:'Oswald',sans-serif;">${t('thankYouForSigningUpEarlyToSportLeague')}</p>
              <div style="background-color:#FFFFFF;padding:1rem;border-radius:20px;">
                ${t('getReadyForSportLeagueText')}
              </div>
              <div style="border-bottom:2px solid #FABB00;width:100%;margin-top:3rem;margin-bottom:1rem;"></div>
              <table style="width:100%">
                <tr style="width:100%;">
                  <td style="width:100%;text-align:center;">
                    <a href='https://www.instagram.com/sportleague.sv' target='_blank' rel='noreferrer' style="text-decoration:none;">
                      <img
                        alt="socialsIcon"
                        src="https://firebasestorage.googleapis.com/v0/b/sport-league-e35d7.appspot.com/o/assets%2Femails%2FemailInstagramIcon.png?alt=media&token=45fba107-170f-4eb6-9881-384f6797336e"
                        style="width:52px;height:52px;margin-right:1rem;max-height:52px"
                      />
                    </a>
                    <a href='https://www.facebook.com/people/Sport-League/100086265026537' target='_blank' rel='noreferrer' style="text-decoration:none;">
                      <img
                        alt="socialsIcon"
                        src="https://firebasestorage.googleapis.com/v0/b/sport-league-e35d7.appspot.com/o/assets%2Femails%2FemailFacebookIcon.png?alt=media&token=c452a797-681e-4b34-a42c-87a4c982fee8"
                        style="width:52px;height:52px;margin-right:1rem;max-height:52px"
                      />
                    </a>
                    <a href='https://twitter.com/sportleaguesv?t=GCJQbcarcRruthH2qq2WRA' target='_blank' rel='noreferrer' style="text-decoration:none;">
                      <img
                        alt="socialsIcon"
                        src="https://firebasestorage.googleapis.com/v0/b/sport-league-e35d7.appspot.com/o/assets%2Femails%2FemailXIcon.png?alt=media&token=b8ef48f5-5dcf-4f28-9e99-518e4471c955"
                        style="width:52px;height:52px;max-height:52px"
                      />
                    </a>
                  </td>
                </tr>
              </table>
              <div style="border-bottom:2px solid #FABB00;width:100%;margin-bottom:1rem;margin-top:0.5rem;"></div>
              <div style="color:#FFFFFF;font-size:14px;">
                <p style="margin:0px">
                  ${t('howDoesItWork')}
                </p>
                <p style="margin:0px">
                  1. ${t('join')}
                </p>
                <p style="margin:0px">
                  2. ${t('predict')}
                </p>
                <p style="margin:0px">
                  3. ${t('earn')}
                </p>
                <p style="margin:0px">
                  4. ${t('redeem')}
                </p>
              </div>
              <p style="color:#FFFFFF;text-align:center;margin-bottom:1rem;margin-top:2rem;font-size:14px;">
                © 2023 Sport League, ${t('allRightsReserved')}
              </p>
            </div>
            </td>
            </tr>
            </table>
            `
        }
      });

      Swal.fire({
        text: t('earlySignupComplete'),
        icon: 'success',
        confirmButtonColor: '#005EE1',
        confirmButtonText: "Okay",
      });

      setIsLoading(false)
    } else {
      Swal.fire({
        text: t('pleaseEnterYourEmail'),
        icon: 'warning',
        confirmButtonColor: '#005EE1',
        confirmButtonText: "Okay",
      })
    }
  }

  const sendContactForm = async () => {
    if (contactFormEmail && contactFormMessage){
      setIsLoading(true);

      await addDoc(collection(db, "mail"), {
        to: [
          'contact@sportleague.net'
        ],
        message: {
          subject: 'You have a new message!',
          html: `
            <table border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse:collapse; padding:0; margin:0px;">
            <tr valign="top">
            <td align="center">
            <div style="background-color:#111835;padding:1rem;font-family: 'Poppins', sans-serif;max-width:400px;margin-top:-8px;margin-bottom:-8px">
              <style>
                @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
                @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
              </style>
              <div style="display:flex;justify-content:center;">
                <img
                  alt="logo"
                  src="https://res.cloudinary.com/da3qtg7t5/image/upload/v1702401521/Sport%20League/Logos/01_-_SL_Logo_3_qfyqdr.png"
                  style="width:100px;margin-bottom:2rem;margin-top:1rem"
                />
              </div>
              <div style="border-bottom:2px solid #FABB00;width:100%;margin-bottom:1rem;"></div>
              <p style="color:#FABB00;text-align:center;font-size:24px;font-weight:bold;font-family:'Oswald',sans-serif;">NEW MESSAGE</p>
              <div style="background-color:#FFFFFF;padding:1rem;border-radius:20px;">
                Hi! You have a new message from ${contactFormEmail} who says:<br/>
                ${contactFormMessage}<br/><br/>
                Best regards,<br/>
                Sport League
              </div>
              <div style="border-bottom:2px solid #FABB00;width:100%;margin-top:3rem;margin-bottom:1rem;"></div>
              <table style="width:100%">
                <tr style="width:100%;">
                  <td style="width:100%;text-align:center;">
                    <a href='https://www.instagram.com/sportleague.sv' target='_blank' rel='noreferrer' style="text-decoration:none;">
                      <img
                        alt="socialsIcon"
                        src="https://firebasestorage.googleapis.com/v0/b/sport-league-e35d7.appspot.com/o/assets%2Femails%2FemailInstagramIcon.png?alt=media&token=45fba107-170f-4eb6-9881-384f6797336e"
                        style="width:52px;height:52px;margin-right:1rem;max-height:52px"
                      />
                    </a>
                    <a href='https://www.facebook.com/people/Sport-League/100086265026537' target='_blank' rel='noreferrer' style="text-decoration:none;">
                      <img
                        alt="socialsIcon"
                        src="https://firebasestorage.googleapis.com/v0/b/sport-league-e35d7.appspot.com/o/assets%2Femails%2FemailFacebookIcon.png?alt=media&token=c452a797-681e-4b34-a42c-87a4c982fee8"
                        style="width:52px;height:52px;margin-right:1rem;max-height:52px"
                      />
                    </a>
                    <a href='https://twitter.com/sportleaguesv?t=GCJQbcarcRruthH2qq2WRA' target='_blank' rel='noreferrer' style="text-decoration:none;">
                      <img
                        alt="socialsIcon"
                        src="https://firebasestorage.googleapis.com/v0/b/sport-league-e35d7.appspot.com/o/assets%2Femails%2FemailXIcon.png?alt=media&token=b8ef48f5-5dcf-4f28-9e99-518e4471c955"
                        style="width:52px;height:52px;max-height:52px"
                      />
                    </a>
                  </td>
                </tr>
              </table>
              <div style="border-bottom:2px solid #FABB00;width:100%;margin-bottom:1rem;margin-top:0.5rem;"></div>
              <p style="color:#FFFFFF;text-align:center;margin-bottom:1rem;font-size:14px">
                © 2023 Sport League, all rights reserved
              </p>
            </div>
            </td>
            </tr>
            </table>
            `
        }
      });

      await addDoc(collection(db, "mail"), {
        to: [
          earlySignupEmail
        ],
        message: {
          subject: t('weGotYourMessage'),
          html: `
            <table border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse:collapse; padding:0; margin:0px;">
            <tr valign="top">
            <td align="center">
            <div style="background-color:#111835;padding:1rem;font-family: 'Poppins', sans-serif;max-width:400px;margin-top:-8px;margin-bottom:-8px">
              <style>
                @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
                @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
              </style>
              <div style="display:flex;justify-content:center;">
                <img
                  alt="logo"
                  src="https://res.cloudinary.com/da3qtg7t5/image/upload/v1702401521/Sport%20League/Logos/01_-_SL_Logo_3_qfyqdr.png"
                  style="width:100px;margin-bottom:2rem;margin-top:1rem"
                />
              </div>
              <div style="border-bottom:2px solid #FABB00;width:100%;margin-bottom:1rem;"></div>
              <p style="color:#FABB00;text-align:center;font-size:24px;font-weight:bold;font-family:'Oswald',sans-serif;">${t('weGotYourMessageCaps')}</p>
              <div style="background-color:#FFFFFF;padding:1rem;border-radius:20px;">
                ${t('weGotYourMessageText')}
              </div>
              <div style="border-bottom:2px solid #FABB00;width:100%;margin-top:3rem;margin-bottom:1rem;"></div>
              <table style="width:100%">
                <tr style="width:100%;">
                  <td style="width:100%;text-align:center;">
                    <a href='https://www.instagram.com/sportleague.sv' target='_blank' rel='noreferrer' style="text-decoration:none;">
                      <img
                        alt="socialsIcon"
                        src="https://firebasestorage.googleapis.com/v0/b/sport-league-e35d7.appspot.com/o/assets%2Femails%2FemailInstagramIcon.png?alt=media&token=45fba107-170f-4eb6-9881-384f6797336e"
                        style="width:52px;height:52px;margin-right:1rem;max-height:52px"
                      />
                    </a>
                    <a href='https://www.facebook.com/people/Sport-League/100086265026537' target='_blank' rel='noreferrer' style="text-decoration:none;">
                      <img
                        alt="socialsIcon"
                        src="https://firebasestorage.googleapis.com/v0/b/sport-league-e35d7.appspot.com/o/assets%2Femails%2FemailFacebookIcon.png?alt=media&token=c452a797-681e-4b34-a42c-87a4c982fee8"
                        style="width:52px;height:52px;margin-right:1rem;max-height:52px"
                      />
                    </a>
                    <a href='https://twitter.com/sportleaguesv?t=GCJQbcarcRruthH2qq2WRA' target='_blank' rel='noreferrer' style="text-decoration:none;">
                      <img
                        alt="socialsIcon"
                        src="https://firebasestorage.googleapis.com/v0/b/sport-league-e35d7.appspot.com/o/assets%2Femails%2FemailXIcon.png?alt=media&token=b8ef48f5-5dcf-4f28-9e99-518e4471c955"
                        style="width:52px;height:52px;max-height:52px"
                      />
                    </a>
                  </td>
                </tr>
              </table>
              <div style="border-bottom:2px solid #FABB00;width:100%;margin-bottom:1rem;margin-top:0.5rem;"></div>
              <div style="color:#FFFFFF;font-size:14px;">
                <p style="margin:0px">
                  ${t('howDoesItWork')}
                </p>
                <p style="margin:0px">
                  1. ${t('join')}
                </p>
                <p style="margin:0px">
                  2. ${t('predict')}
                </p>
                <p style="margin:0px">
                  3. ${t('earn')}
                </p>
                <p style="margin:0px">
                  4. ${t('redeem')}
                </p>
              </div>
              <p style="color:#FFFFFF;text-align:center;margin-bottom:1rem;margin-top:2rem;font-size:14px;">
                © 2023 Sport League, ${t('allRightsReserved')}
              </p>
            </div>
            </td>
            </tr>
            </table>
            `
        }
      });

      Swal.fire({
        text: t('contactFormSubmitted'),
        icon: 'success',
        confirmButtonColor: '#005EE1',
        confirmButtonText: "Okay",
      });

      setIsLoading(false);
    } else {
      Swal.fire({
        text: t('pleaseEnterYourEmailAndAMessage'),
        icon: 'warning',
        confirmButtonColor: '#005EE1',
        confirmButtonText: "Okay",
      })
    }
  }

  const AutoplayVideo = () => (
    <div
      style={{height: '100vh'}}
      dangerouslySetInnerHTML={{
        __html: `
        <video loop muted autoPlay playsinline>
          <source src="https://firebasestorage.googleapis.com/v0/b/sport-league-e35d7.appspot.com/o/assets%2Fvideos%2FlandingPageBackground1_u6kvzp.mp4?alt=media&token=fd5fdea1-f06b-4042-b8ad-0c49aadf449b" type="video/mp4"/>
        </video>`
      }}
    />
  )

  // eslint-disable-next-line
  const [prizes, setPrizes] = useState([
    'https://firebasestorage.googleapis.com/v0/b/sport-league-e35d7.appspot.com/o/assets%2Fprizes%2FGIFT%20CARD%201%20copia%2010.png?alt=media&token=1b868b3e-8988-411d-84dc-c1a217fe9e6a',
    'https://firebasestorage.googleapis.com/v0/b/sport-league-e35d7.appspot.com/o/assets%2Fprizes%2FGIFT%20CARD%201%20copia%2011.png?alt=media&token=fac23556-f26f-4bcc-ba33-4006e97f196a',
    'https://firebasestorage.googleapis.com/v0/b/sport-league-e35d7.appspot.com/o/assets%2Fprizes%2FGIFT%20CARD%201%20copia%2012.png?alt=media&token=b5a75e74-1459-4116-b77e-18c01f6b38c7',
    'https://firebasestorage.googleapis.com/v0/b/sport-league-e35d7.appspot.com/o/assets%2Fprizes%2FGIFT%20CARD%201%20copia%2013.png?alt=media&token=d327e493-2990-405a-9339-e894a495c8af',
    'https://firebasestorage.googleapis.com/v0/b/sport-league-e35d7.appspot.com/o/assets%2Fprizes%2FGIFT%20CARD%201%20copia%2014.png?alt=media&token=2cd0ee16-32f2-4fc1-8ad2-281895eb26eb',
    'https://firebasestorage.googleapis.com/v0/b/sport-league-e35d7.appspot.com/o/assets%2Fprizes%2FGIFT%20CARD%201%20copia%2015.png?alt=media&token=1ff2f03f-47ad-4f79-8143-731a4e23d467',
    'https://firebasestorage.googleapis.com/v0/b/sport-league-e35d7.appspot.com/o/assets%2Fprizes%2FGIFT%20CARD%201%20copia%202.png?alt=media&token=2b0a1f8c-a93d-414c-9e45-bfec1c8117b5',
    'https://firebasestorage.googleapis.com/v0/b/sport-league-e35d7.appspot.com/o/assets%2Fprizes%2FGIFT%20CARD%201%20copia%203.png?alt=media&token=a73c10e7-23f2-4625-8f7c-873210c16abd',
    'https://firebasestorage.googleapis.com/v0/b/sport-league-e35d7.appspot.com/o/assets%2Fprizes%2FGIFT%20CARD%201%20copia%204.png?alt=media&token=6d5283fb-a099-44f1-88a4-b0fbe574c62e',
    'https://firebasestorage.googleapis.com/v0/b/sport-league-e35d7.appspot.com/o/assets%2Fprizes%2FGIFT%20CARD%201%20copia%205.png?alt=media&token=0b042c10-6898-4cb3-a2d9-7a3c8ca56f55',
    'https://firebasestorage.googleapis.com/v0/b/sport-league-e35d7.appspot.com/o/assets%2Fprizes%2FGIFT%20CARD%201%20copia%206.png?alt=media&token=e7f7a378-b71f-4fd3-a54d-ffd4c1ccd7a5',
    'https://firebasestorage.googleapis.com/v0/b/sport-league-e35d7.appspot.com/o/assets%2Fprizes%2FGIFT%20CARD%201%20copia%207.png?alt=media&token=a6e1ad3c-a1fa-4ef9-8874-26299b1c961b',
    'https://firebasestorage.googleapis.com/v0/b/sport-league-e35d7.appspot.com/o/assets%2Fprizes%2FGIFT%20CARD%201%20copia%208.png?alt=media&token=68d5a1ef-06e3-4123-a587-7049d62f4e2f',
    'https://firebasestorage.googleapis.com/v0/b/sport-league-e35d7.appspot.com/o/assets%2Fprizes%2FGIFT%20CARD%201%20copia%209.png?alt=media&token=e662be3e-8398-4328-a081-4dbbe4cf990f',
    'https://firebasestorage.googleapis.com/v0/b/sport-league-e35d7.appspot.com/o/assets%2Fprizes%2FGIFT%20CARD%201%20copia.png?alt=media&token=b394fc03-a593-44eb-a304-10e0afdb786c',
    'https://firebasestorage.googleapis.com/v0/b/sport-league-e35d7.appspot.com/o/assets%2Fprizes%2FGIFT%20CARD%201.png?alt=media&token=9c8c70d2-b530-46ab-8edd-a355380bcc0d'
  ])

  if (isLoading) return (
    <div className='loadingPage'>
      <BarLoader color='#005EE1' />
    </div>
  )

  return (
    <div className="App">
      <section id='heroSection'>
        <header>
          <div id='headerLogoContainer' style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <img
              alt="logo"
              src="https://firebasestorage.googleapis.com/v0/b/sport-league-e35d7.appspot.com/o/assets%2Flogos%2F01_-_SL_Logo_2_qqmpsb.png?alt=media&token=9cc7396f-1f27-4048-8422-2f94f89a5fd8"
              style={{
                width: 100,
              }}
              className='hideOnMobile'
            />
            <img
              alt="logo"
              src="https://firebasestorage.googleapis.com/v0/b/sport-league-e35d7.appspot.com/o/assets%2Flogos%2F01_-_SL_Logo_3_qfyqdr.png?alt=media&token=488c83e0-7274-4b31-8e41-24a428973f48"
              style={{
                width: 90,
              }}
              className='hideOnDesktop'
            />
          </div>
          <div id='headerNavLinksContainer' style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <div className='navLanguageToggle'>
              <p onClick={toggleLanguageModal}>
                {activeLanguage === 'en' ? '🇺🇸 ' : '🇪🇸 '}{activeLanguage.toUpperCase()}
              </p>
              <Modal id='languageModal' isOpen={languageModalIsOpen} toggle={toggleLanguageModal} size={'lg'}>        
                <ModalBody>
                  <div className='modalHeader'>
                    <div></div>
						        <IoClose onClick={toggleLanguageModal} />
					        </div>
                  <div>
                    <h1>
                      LANGUAGE
                    </h1>
                    <div>
                      <button onClick={() => toggleLanguage('en')} className={activeLanguage === 'en' ? 'primaryBtn' : 'primaryBtnOutline'} style={{width: '100%', marginBottom: '0.5rem'}}>
                        🇺🇸 ENGLISH
                      </button>
                      <button onClick={() => toggleLanguage('es')} className={activeLanguage === 'es' ? 'primaryBtn' : 'primaryBtnOutline'} style={{width: '100%'}}>
                        🇪🇸 ESPAÑOL
                      </button>
                    </div>
                  </div>
                </ModalBody>
              </Modal>
              {/* <p onClick={() => toggleLanguage('en')} style={{paddingRight: '0.5rem', borderRight: '1px solid white'}} className={classnames(
                {
                  active: activeLanguage === 'en'
                }
              )}>
                🇺🇸 EN
              </p>
              <p onClick={() => toggleLanguage('es')} style={{paddingLeft: '0.5rem'}} className={classnames(
                {
                  active: activeLanguage === 'es'
                }
              )}>
                🇪🇸 ES
              </p> */}
            </div>
            {/* <Link
              to="earlySignupSection"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
            > */}
            <a href='https://sportleague-app.netlify.app/' target='_blank' rel='noreferrer'>
              <button id='navBtn'>
                {t('playNow')}
              </button>
            </a>
            {/* </Link> */}
          </div>
        </header>
        <AutoplayVideo />
        <div id='heroText'>
          <p id='heroSubtext'>
            {t('becomeAFan')}
          </p>
          <p id='heroMaintext'>
            {t('rewardYourPassion')}
          </p>
        </div>
      </section>
      <section id='featuresSection'>
        <h1>
          {t('goAllIn')}
        </h1>
        <p style={{marginBottom: '1rem'}}>
          {t('goAllInSubtext')}
        </p>
        <div className='card'>
          <h1>
            1
          </h1>
          <div>
            <h2>
              {t('join')}
            </h2>
            <p>
              {t('joinSubtext')}
            </p>
          </div>
        </div>
        <div className='card'>
          <h1>
            2
          </h1>
          <div>
            <h2>
              {t('predict')}
            </h2>
            <p>
              {t('predictSubtext')}
            </p>
          </div>
        </div>
        <div className='card'>
          <h1>
            3
          </h1>
          <div>
            <h2>
              {t('earn')}
            </h2>
            <p>
              {t('earnSubtext')}
            </p>
          </div>
        </div>
        <div className='card'>
          <h1>
            4
          </h1>
          <div>
            <h2>
              {t('redeem')}
            </h2>
            <p>
              {t('redeemSubtext')}
            </p>
          </div>
        </div>
      </section>
      {/*
      <section id='prizesSection'>
        <h1>
          {t('prizes')}
        </h1>
        <ScrollMenu>
          {
            prizes.map(prize => {
              return (
                <img
                  alt="prize"
                  src={prize}
                  style={{
                    width: 400,
                  }}
                />
              )
            })
          }
        </ScrollMenu>
      </section>
       */}
      <section id='earlySignupSection'>
        <h1>
          {t('playNow')}
        </h1>
        <p style={{marginBottom: '1rem'}}>
          {t('playNowText')}<span style={{color: '#FABB00'}}>{t('150ShopTickets')}</span>!
          {/* {t('&ExclusiveRewards')} */}
        </p>
        <a href='https://sportleague-app.netlify.app/' target='_blank' rel='noreferrer'>
          <button className='primaryBtn' style={{width: '100%'}}>
            {t('playNow')}
          </button>
        </a>
        {/* <div className='inputFieldButtonGroup'>
          <input
            type='text'
            className="mainInput"
            value={earlySignupEmail}
            onChange={(e) => setEarlySignupEmail(e.target.value)}
            placeholder={t('enterYourEmail')}
          />
          <button onClick={earlySignup} className='primaryBtn'>
            {t('signUp')}
          </button>
        </div> */}
      </section>
      {/*
      <section id='earlySignupSection'>
        <h1>
          {t('youIn')}
        </h1>
        <p style={{marginBottom: '1rem'}}>
          {t('youInSubtext')}<span style={{color: '#FABB00'}}>{t('150ShopTickets')}</span>{t('&ExclusiveRewards')}
        </p>
        <div className='inputFieldButtonGroup'>
          <input
            type='text'
            className="mainInput"
            value={earlySignupEmail}
            onChange={(e) => setEarlySignupEmail(e.target.value)}
            placeholder={t('enterYourEmail')}
          />
          <button onClick={earlySignup} className='primaryBtn'>
            {t('signUp')}
          </button>
        </div>
      </section>
      */}
      <section id='contactUsSection'>
        <h1>
          {t('contactUs')}
        </h1>
        <p style={{marginBottom: '1rem'}}>
          {t('contactUsSubtext')}
        </p>
        {/* <p style={{marginBottom: '1rem'}}>
          {t('contactUsPhone')} <b>+503 0000 0000</b>.
        </p> */}
        <input
          type='text'
          className="mainInput"
          value={contactFormEmail}
          onChange={(e) => setContactFormEmail(e.target.value)}
          placeholder={t('enterYourEmail')}
          style={{marginBottom: '0.5rem'}}
        />
        <textarea
          className="mainInput"
          value={contactFormMessage}
          onChange={(e) => setContactFormMessage(e.target.value)}
          placeholder={t('enterYourMessage')}
          style={{marginBottom: '0.5rem', height: 150, borderRadius: 20, paddingTop: '1rem'}}
        >
        </textarea>
        <button onClick={sendContactForm} className='primaryBtn' style={{width: '100%'}}>
          {t('send')}
        </button>
      </section>
      <footer>
        <div id='footerRow'>
          <p>
            © 2023 Sport League, {t('allRightsReserved')}
          </p>
          <div style={{display: 'flex', flexDirection: 'row'}}>
          <a href='https://www.instagram.com/sportleague.sv' target='_blank' rel='noreferrer' className='socialsIconContainer'>
            <BsInstagram />
          </a>
          <a href='https://www.facebook.com/people/Sport-League/100086265026537' target='_blank' rel='noreferrer' className='socialsIconContainer'>
            <FaFacebookF />
          </a>
          <a href='https://twitter.com/sportleaguesv?t=GCJQbcarcRruthH2qq2WRA' target='_blank' rel='noreferrer' className='socialsIconContainer'>
            <BsTwitterX />
          </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
